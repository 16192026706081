import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Nav from '../components/nav'
import ExploreIcon from '../components/explore'

const Story = ({ data }) => {
  return (
    <>
      <SEO title="Stories" />
      <Nav>
        <div className="col text-left">
          <Link className="target fill-dark" to="/explore" style={{height:"1.875rem"}}><ExploreIcon /></Link>
        </div>
      </Nav>
      <ReactFullpage
        // fullpage options
        licenseKey={'C1912978-A39F4CE4-9F6AC28B-DFB573DA'}
        sectionsColor={['#e8e7e3']}
        lazyLoading
        autoScrolling={false}
        scrollBar={false}

        render={() => (
          <ReactFullpage.Wrapper>
            <section className="section" key="section0" id="section0">
              <div className="container mt-5 pt-5 px-5">
                <h2 className="title">
                  Stories
                </h2>
                <div className="row">
                  {data.allMarkdownRemark.edges
                    .filter(data => {
                      return data.node.fields.slug.includes('story')
                    })
                    .map(({ node }) => (
                      <div key={node.id} className="col-md-6 col-lg-4 mt-0 mb-3 my-md-3">
                        <div className="card overflow-hidden h-100">
                          {node.frontmatter.thumbnail ? (
                            <Img
                              className="img-fluid w-100"
                              fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
                              alt="Inside Exchange 106"
                              style={{height:"25rem"}}
                            />
                          ) : ''}
                          <div className="card-img-overlay pl-0">
                            <div className="overlay bg-dark p-3">
                              <h5 className="card-title">{node.frontmatter.title}</h5>
                              <p className="card-text">{node.excerpt}</p>
                            {/* </div>
                            <div className="card-footer overlay border-0 bg-dark"> */}
                              <p className="card-text">
                                <Link
                                  to={node.fields.slug}
                                >
                                  Continue reading
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      // <div key={node.id} className="col-md-6 col-lg-4">
                      //   {node.frontmatter.thumbnail ? (
                      //     <Img
                      //       className="img-fluid w-100"
                      //       fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
                      //       alt="Inside Exchange 106"
                      //     />
                      //   ) : ''}
                        
                      //   <h4>
                      //     {node.frontmatter.title}
                      //   </h4>
                      //   <p>{node.excerpt}</p>
                      //   <p>
                      //     <Link
                      //       to={node.fields.slug}
                      //     >
                      //       Continue reading
                      //     </Link>
                      //   </p>
                      // </div>
                    ))
                  }
                </div>
              </div>

              <div className="container p-5">
                <h2 className="title">
                  Media
                </h2>
                <div className="row">
                  {data.allMarkdownRemark.edges
                    .filter(data => {
                      return data.node.fields.slug.includes('media')
                    })
                    .map(({ node }) => (
                      <div key={node.id} className="col-md-6 col-lg-4">
                        <h4>
                          {node.frontmatter.title}
                        </h4>
                        <p>{node.frontmatter.description}</p>
                        {node.frontmatter.thumbnail ? (
                          <Img
                            className="mb-3"
                            fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
                            alt={node.frontmatter.title}
                          />
                        ) : ''}
                        <p><a href={node.frontmatter.pdf.publicURL} target="_blank" rel="noopener noreferrer">{node.frontmatter.pdfTitle ? node.frontmatter.pdfTitle : node.frontmatter.title} &#8599;</a></p>
                        {/* <p dangerouslySetInnerHTML={{ __html: node.html }} /> */}
                      </div>
                    ))
                  }
                </div>
              </div>
            </section>
          </ReactFullpage.Wrapper>
        )}
      />
    </>
  )
}

export default Story

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}, filter: {fields: {slug: {regex: "/story|media/"}}}
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(formatString: "DD MMMM, YYYY")
            pdf {
              publicURL
            }
            pdfTitle
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 590) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt(pruneLength: 110)
          html
        }
      }
    }
  }
`